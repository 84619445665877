@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}
.login_page_reset_password {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}
.login_div {
  font-weight: 600 !important;
  font-size: 26px;
  line-height: 37px;
  font-family: jost;
}
.email_address_login {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  font-family: jost !important;
}
.input-group{
  position: relative;
}
.reset_login_page {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: jost;
}
.text-center{
  text-align: center;
}
.remember_login_page {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: jost;
}
.login_btn_btn {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  font-family: jost;
  padding: 9px;
  border: none;
  margin-top: 10px;
}
.have_account {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  font-family: jost;
}
.reset_button {
  color: rgba(226, 19, 34, 1) !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.reset_button:hover {
  color: rgba(226, 19, 34, 1) !important;
}
.login_form_class {
  margin-top: 2rem;
  /* margin-left: 6rem;
  margin-bottom: 3rem; */
  background: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #80808059;
  margin: auto;
}
.input_login_page {
  background-color: rgba(247, 247, 248, 1) !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 9px;
    border-radius: 10px;
    width: 94%;
    position: relative;
    margin: 8px 0px;
}
.input_login_page:active {
  background-color: rgba(247, 247, 248, 1) !important;
  border: none !important;
  box-shadow: none !important;
}
.input_login_page:focus {
  background-color: rgba(247, 247, 248, 1) !important;
  border: none !important;
  box-shadow: none !important;
}
.form-outline{
  display: flex;
      flex-direction: column;
      margin: 10px 0px;
  
}
.my_eye_icon {
  border: none !important;
    cursor: pointer !important;
    position: absolute;
    right: 11px;
    top: 15px;
}
.frisbee_login_login{
  height: 100vh;
  flex-direction: column;
}