@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=DM+Sans:opsz@9..40&family=Jost:wght@500&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@600&family=Pacifico&family=Poppins:wght@500&display=swap");

* {
  font-family: "Jost", sans-serif;
}

/* .main_dashbord {
} */
.star_reviews span {
  margin-right: 10px;
}

.main_heading {
  font-family: "Jost", sans-serif;
  font-size: 24px;
  color: #263238;
  font-weight: 700;
  line-height: 37.53px;
}

.btn_icon {
  text-align: end;
  border: navajowhite;
  padding: 4px 11px 7px;
  border-radius: 50%;
  background-color: #fce9e8;
  color: #e1251b;
  font-size: 21px;
}

.btn_icon:hover {
  color: #e1251b;
  background-color: #fce9e8;
  border-color: white;
}

.btn_icon:active {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
}

.btn_icon:focus {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
}

.btn_icon:focus-visible {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
  border: transparent;
}

.top_nav_oo .btn-primary {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
  border: transparent;
}

.top_nav_oo button::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none;
}

.search {
  position: relative;
}

.search_box #search_icon {
  position: absolute;
  left: 14px;
  top: 13px;
  font-size: 20px;
}

.search_box {
  position: relative;
}

.search::placeholder {
  color: #000000;
  font-size: 16px;
  font-family: "Jost", sans-serif;
  line-height: 20px;
}

.search_input {
  padding: 9px 10px 11px 43px;
  border: 1px solid #f3f3f3;
  width: 100%;
  border-radius: 7px;
}

.transction_main_Card h1,
.review h1 {
  color: #263238;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  font-weight: 600;
}

.transction_main_Card {
  display: flex;
  justify-content: space-between;
}

.transction_main_Card select {
  border: none;
  font-size: 13px;
  color: #263238;
  font-weight: 400;
  width: 74px;
}

.transaction_Card,
.review {
  background-color: #ffffff;
  padding: 11px 15px;
  margin: 17px 0px;
  border-radius: 10px;
}

.star_reviews ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
}

.star {
  color: #f29339;
  font-size: 23px;
  margin-right: 5px;
}

.select_option {
  color: #b4b4b4 !important;
}

.half-star {
  color: #f29339;
  opacity: 20%;
  font-size: 23px;
}

.sticky {
  position: fixed;
  z-index: 99999;
  background-color: #ffffff;
  width: 100%;
  padding: 10px 22px;
  top: 0px;
  left: 0px;
  box-shadow: 0 4px 12px 0 #0000000a;
  color: var(--color-default);
}

.star_reviews {
  margin-right: 23px;
  font-size: 32px;
  font-weight: 500;
  color: #263238;
  display: flex;
  align-items: baseline;
  justify-content: end;
}

.star_reviews ul {
  padding: 0px;
  margin: 0px;
  display: flex;
}

.tran_details img {
  width: 41px;
  height: 41px;
}

.transaction_Card ul {
  padding: 0px 5px 0px;
  width: 100%;
  margin: 0px;
}

.tran_details {
  display: flex;
}

.transaction_Card ul li:nth-last-child(1) {
  border-bottom: 0px solid #cccccca6;
}

.transaction_form {
  display: flex;
}

.margin-right {
  margin-right: 10px;
}

.transaction_form .transaction_Card {
  width: 100%;
  height: 120px;
  position: relative;
}

.ant-select-selection-item {
  font-size: 12px !important;
  color: rgba(180, 180, 180, 1);
}

.transaction_Card ul li {
  display: flex;
  list-style: none;
  padding: 14px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #cccccca6;
  margin: 10px 0px;
}

.trans_name {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.23px;
}

.trans_id {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.23px;
}

.tran_infor {
  margin-left: 20px;
}

.tran_infor p {
  margin: 0px;
}

.price {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  line-height: 20.23px;
}

.sales_text {
  font-size: 18px;
  font-weight: 600;
  line-height: 37px;
  margin: 0px;
  position: absolute;
  bottom: 0px;
  color: rgba(48, 194, 35, 1);
}

.presentation {
  font-size: 12px;
  margin: 15px 0px 12px;
  color: #263238;
  font-weight: 400;
}
.transaction_Card  span svg{
  border-radius: 100px;
    color: #2e2a2b;
    opacity: 19%;
    font-size: 21px;
    margin-bottom: 5px;
}

.unit {
  color: #7b7b7b;
  font-size: 15px;
}

/* .header_top_nav{

} */
.admin_dashboard_dropdwon:active {
  color: black !important;
  text-decoration: none;
  background-color: white !important;
}

.main_dashboard_tooltip {
  display: flex;
  flex-direction: row;
}

.notification_top_navbar_for_frisbeee {
  width: 100%;
}

.notification_top_navbar_for_frisbeee {
  width: 100%;
}

.message_section_all_div_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  margin-top: 26px;
  gap: 25px;
}

.message_section_all_list_item_first {
  width: 40%;
  background: white;
  border-radius: 12px;
  height: 615px;
}

.message_section_all_list_item_first .list {
  height: 615px;
}

.message_section_all_list_item_second {
  width: 60%;
  background: white;
  border-radius: 12px;
  height: 615px;
}

.dashboard_top_heading_user {
  font-size: 20px !important;
}

.top_nav_profile a {
  text-decoration: none;
  color: #000;
}


/* Apply alternating background colors for each td */
.table tbody tr:nth-child(even) td {
  background-color: #ffffff;
  /* White for even rows */
}

.table tbody tr:nth-child(odd) td {
  background-color: #fbf4f3;
  /* Light gray for odd rows */
}
.table>tbody>tr:hover>* {
  background-color: #c1c1c1 !important;
}

/* Basic table styling */
.table td {
  /* padding: 10px; */
  border: 1px solid #ccc;
  /* text-align: center;    */
  vertical-align: middle; 
}
.table td p{
  margin-bottom: 0 !important; 
}

.filter-text {
  margin-right: 20px;
}

.filter_anchor_text {
  padding-top: 5px;
}

.common_component_form {
  display: flex;
  flex-direction: row;
  gap: 11px;
}

.common_file_notification {
  font-size: 45px;
  color: rgb(237 110 104);
}

.common_inventory_heading {
  font-family: jost;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}

.project-tab {
  padding: 10%;
  margin-top: -8%;
}

.project-tab #tabs {
  background: #007b5e;
  color: #eee;
}

.project-tab #tabs h6.section-title {
  color: #eee;
}

.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0062cc;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 3px solid !important;
  font-size: 16px;
  font-weight: bold;
}

.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #0062cc;
  font-size: 16px;
  font-weight: 600;
}

.project-tab .nav-link:hover {
  border: none;
}

.project-tab thead {
  background: #f3f3f3;
  color: #333;
}

.project-tab a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.inventory_tabs_main_div {
  margin-top: 2%;
}

.MuiBox-root.css-1gsv261 {
  border-bottom: none !important;
}

th {
  font-family: "Jost", sans-serif !important;
  font-weight: 500 !important;
}

td {
  font-family: "Jost", sans-serif !important;
  font-weight: 400 !important;
}

.MuiBox-root.css-19kzrtu {
  padding: 0 !important;
  margin-top: 40px !important;
}

.inventory_all_tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  gap: 10px;
  border-radius: 9px;
  padding: 10px 0px;
  align-items: center;
}

.filters_inventory {
  margin-top: 9px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}

.filters_inventory_div {
  margin-top: 9px;
  margin-right: 10px;
}

.payment_select_tag .ant-select-selector {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
}

.payment_select_tag {
  margin-right: 50px;
}

.all_tabs_value {
  margin-left: 20px;
}

.filter_btn_invontry {
  font-family: jost;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: rgba(225, 37, 27, 1) !important;
}

/* // For miui tab */
.css-1q2h7u5.Mui-selected {
  color: red !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 24.57px !important;
  font-family: "Jost" !important;
}

.css-ttwr4n {
  background-color: red !important;
}

.css-1q2h7u5 {
  text-transform: capitalize !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: rgba(225, 37, 27, 1) !important;
}

.table_pagination_sales_order {
  display: flex;
  justify-content: end;
  padding: 1px;
}

.user_iconses {
  margin-right: 3px;
}

#search_bar {
  width: 35%;
  margin-left: 90px;
}

.show_span_edit {
  margin-left: 5px;
}

.fa_filter_rating_review a {
  text-decoration: none;
}

.tabs_main_contnet {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: "Jost", sans-serif;
  color: #263238 !important;
}
.bars svg{
    color: #af1250;
    font-size: 20px;
    margin-right: 41px !important;
}
.Inventory_edit_page_main_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.inventory_save_button{
  background-color: #a80f4f !important;
  border: none !important;

}
.dataBase_table_Add_Data {
  margin-top: 25px;
}
.amount_heading{
  font-size: 20px;
}

.invetory_title_div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inventory_form_main_contnet {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inventory_form_main_content_form_section {
  display: flex;
  gap: 15px;
}

.inventory_edit_submit_btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inventory_save_button {
  width: 15%;
  background: #000;
  border-color: unset;
  padding: 4px;
  font-size: 16px !important;
}

.inventory_save_button:hover {
  background: #000;
  border-color: unset;
}

.all_label_margin {
  margin-top: 15px;
}

.inventory_save_button:focus-visible {
  background: #000;
  border-color: unset;
  box-shadow: none;
}

.inventory_save_button:active {
  background: #000;
  border-color: unset;
  box-shadow: none;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-family: "Jost", sans-serif !important;
}

.inventory_edit_page_heading {
  font-family: jost;
  font-size: 26px;
  line-height: 37px;
  font-weight: 700;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 150px !important;
}

.show_span {
  margin-left: 10px;
}

.show_span_edit {
  margin-left: 17px;
}

.my_reply_select_tag {
  height: 54px;
  border-radius: 28px;
}

.my_input_font_size {
  font-size: 14px !important;
}

.my_enqiry_form_text_area {
  border-radius: 28px;
}

/* .my_delete_popup_paragraph {
  padding-inline: 30px;
} */

.my_delete_popup_paragraph p {
  margin-top: 25px;
  font-size: 17px;
}

.please_a_reason {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}

.please_a_reason div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.please_a_reason p {
  margin-top: 0 !important;
}

.myDialogPopUp {
  border-radius: 10px;
  width: 40%;
}

.table-bordered> :not(caption)>*>* {
  /* padding: 20px 15px; */
  padding: 10px 15px;
  font-size: 15px;
  font-family: "Jost";
}

.table-bordered> :not(caption)>*>* {
  border-width: 0px !important;
}

tr,
th,
td {
  border-bottom: 1px solid #f5f5f5 !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
}

.inventory_edit_page_popup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 13px;
}

.inventory_popup_create_promocodes {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.inventory_upload_file_main_div {
  border: 1px solid rgba(235, 235, 235, 1);
  border-radius: 6px;
}

.inventory_edit_upload {
  margin-left: 15px;
}

.inventroy_edit_page_antd_uplaod_button {
  border: 0;
  background: none;
}

.inventroy_edit_page_antd_choose_file {
  font-family: jost;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.inventory_edit_upload:where(.css-dev-only-do-not-override-dkbvqv).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  height: 45px !important;
  margin-top: 10px;
}

.inventory_open_popup_action_btn {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 22px;
  margin-bottom: 10px;
}

.inventory_open_popup_action_btn button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  padding: 4px;
  width: 30%;
}

.inventory_popup_title_heading {
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: "Jost" !important;
  line-height: 31px !important;
}

.inventory_popup_title {
  text-align: center;
  /* margin-bottom: 40px;
  margin-top: 22px; */
}

.inventory_filter_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventory_filter_div img {
  width: 25px;
}

.filter_iconsm {
  margin-right: 5px;
}

.vendor_Add_edit_data input:focus {
  box-shadow: none;
  border: 1px solid black;
}

.vendor_Add_edit_data_gender:focus {
  box-shadow: none;
  border: 1px solid black;
}

.about_us_dragger_file .ant-upload-drag span {
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.about_us_dragger_file .ant-upload-drag:hover {
  border: none;
  outline: none;
  border-color: unset !important;
}

.about_us_dragger_file .ant-upload-drag {
  border: none !important;
}

.about_us_dragger_file .ant-upload-drag span .ant-upload-btn:focus-visible {
  outline: none;
}

.about_us_dragger_file_button {
  background: red;
  width: 15%;
  content: #fff;
  color: #fff;
  font-weight: 400;
  padding: 4px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.verndor_dashboard_add_edit_button .verndor_dashboard_add_edit_button_radio {
  border-start-start-radius: 6px !important;
  border-end-start-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.verndor_dashboard_add_edit_button .verndor_dashboard_add_edit_button_radio1 {
  border-start-start-radius: 6px !important;
  border-end-start-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.vendor_dashbaord_gender_main_section {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  max-width: 100%;
  justify-content: space-evenly;
  padding: 3px;
}

.vendor_dashbaord_gender_main_section input:checked {
  background-color: red;
  border-color: red;
}

.inventory_vendor_add_new_btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.inventory_upload_excel_section label {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
  margin-bottom: 8px;
}

.categories_open_popup_main_contnet span {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
}

.sign_up_file_upload_for_inventory {
  border: 2px solid #dee2e6;
  border-radius: var(--bs-border-radius);
  background: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 2px;
  padding-inline: 14px;
}

.sign_up_file_upload_for_inventory .ant-upload-wrapper .ant-upload-select {
  height: unset !important;
  margin-top: unset !important;
  border: unset !important;
  padding: unset !important;
  width: unset !important;
  background: unset !important;
}

.sign_up_file_upload_for_inventory button {
  padding: 2px 29px;
  background: #2697ff;
  padding: 8px 20px !important;
  color: #fff;
  border-radius: 25px;
  height: unset;
  display: flex;
  flex-direction: row-reverse;
  gap: 13px;
}

.sign_up_file_upload_for_inventory button .ant-btn-icon svg {
  text-align: center;
  align-items: center;
  margin-top: 2px;
}

.sign_up_file_upload_for_inventory button:hover {
  padding: 2px 29px;
  background: #2697ff;
  color: #fff !important;
  border-radius: 25px;
  height: unset;
}

.frisbee_dashbaord_inventory_popup_tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.frisbee_dashbaord_inventory_popup_tabs .MuiTabs-scroller .MuiTabs-indicator {
  display: none;
}

.vendor_dashbaord_signle_product {
  display: flex;
  gap: 13px;
}

.vendor_dashbaord_signle_product input {
  width: 20px;
  color: rgba(48, 194, 35, 1);
}

#specifyColor {
  accent-color: green;
}

.frisbee_dashbaord_pop_up_tabs1 {
  text-transform: capitalize !important;
  font-family: "Jost", sans-serif !important;
  line-height: 20.23px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  border: 2px solid #dee2e6 !important;
  border-radius: 7px !important;
}

.company_registration_document_button {
  color: #c3c3c3;
}

.inventory_open_popup_action_btn_upload_main {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 22px;
  margin-bottom: 10px;
}

.inventory_open_popup_action_btn_upload_main button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  padding: 7px;
  width: 35%;
}

.inventory_select_opetion_popup_modal {
  margin-bottom: 8px;
}

.vendor_popup_product_name {
  font-weight: 600;
  font-size: 16px;
  line-height: 23.12px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.inventory_popup_for_pp {
  margin-top: 30px;
}

.inventory_popup_for_pp p {
  margin-top: 0 !important;
  margin-bottom: 0;
  font-family: "Jost";
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 5px;
}

.vendor_Add_edit_data_form input::placeholder {
  color: #000;
}

.bulk_upload_text {
  color: #000 !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
  margin-bottom: 8px;
}

.vendor_Add_edit_data_form input:focus {
  box-shadow: none;
  border: 1px solid black;
}

.including_uat_vendor {
  color: rgba(135, 135, 135, 1);
  font-weight: 500 !important;
  font-size: 13px !important;
}

.list_bluk_upload_downlaod {
  text-decoration: none;
  text-align: center;
  align-items: center;
  display: flex;
  gap: 2px;
  color: #000 !important;
}

.insurance_owner_deatils p {
  margin-top: 0 !important;
}

.inventory_upload_excel_section label {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
  margin-bottom: 8px;
}

.sign_up_vendor_first_name {
  margin-top: 1.3rem;
}

.notification_select_item_main .css-13cymwt-control:hover {
  border: 1px solid black;
  box-shadow: none;
}

.notification_select_item_main .css-t3ipsp-control {
  border: 1px solid black !important;
  box-shadow: none;
  border-color: black !important;
}

.notification_select_item_main .css-13cymwt-control .css-3w2yfm-ValueContainer {
  padding: 10px 15px !important;
}

/* .term_and_condation_react_quilllll {
  margin-top: 20px;
} */

.all_section_for_label_main_class {
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 13px;
}
.ant-select{
  height: 35px !important;
  padding: 0px !important;
}
.ant-select-selector{
  border: none;
}

.all_lebel_margin_top_main_div {
  margin-top: 15px;
}

.all_top_and_bottom_for_input_text {
  margin-top: 15px;
  margin-bottom: 5px;
}

.map_section_main_div div div input {
  width: 100% !important;
  border-radius: 10px;
  border: 1px solid black !important;
}

.map_section_main_div div {
  margin-bottom: 15px !important;
}

.vendor_collapse_main_div_div_div {
  display: flex;
  width: 100%;
}

.child_main_collapse_class_sec {
  width: 50%;
}

/* ////// */
.eye_button_for_main_class {
  position: relative;
}

.eye_button_for_main_class button {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
  margin-top: 5px;
  border: none;
  background: none;
}

.filter_img_and_add_btn_main {
  display: flex;
  gap: 14px;
  margin-top: 6px;
}

.all_input_tag_tagenter_product_:focus {
  border: 1px solid black !important;
  box-shadow: none !important;
}

.all_lable_for_vendor_dashbaord {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.all_vendor_radio_button_div {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 5px;
  margin-top: 15px;
  margin-right: 10px;
}

.all_top_and_bottom_for_input_text {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 17px;
}

.admin_add_product_select_all_main_div div .ant-select-selector {
  border: none !important;
}

.admin_add_product_select_all_main_div div .ant-select-selector {
  border: none !important;
}

.all_border_bottom_for_hide {
  border-bottom: none !important;
}

.all_save_btn_btn_btn {
  margin-top: 10px;
}

#radio1 {
  accent-color: red !important;
}

#radio2 {
  accent-color: red !important;
}

.store_input_field:focus {
  box-shadow: none;
  border: 1px solid black;
}

.all_map_listing {
  margin: 19px 0 0;
  display: flex;
  list-style: none;
  gap: 23px;
  flex-direction: column;
  padding-left: 0;
}

.all_map_listing li a {
  padding: 6px 12px;
  background: #ff00001f;
  border-radius: 10px;
  color: #000000;
  text-decoration: none;
}


/*invoice*/
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  /* padding: 10px; */
}

.invoice-container {
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.invoice-title {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.price {
  font-weight: bold;
}

.footer {
  margin-top: 50px;
  text-align: center;
}

#downloadPdfButton {
  display: block;
  margin: 30px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logo-image {
  display: flex;
  justify-content: center;
}

.logo-image img {
  width: 25%;
}
.filter_btn_invontry_rating_review{
  color: #000;
}
.MuiTabs-flexContainer button{
  font-size: 13px !important;
}
.ant-collapse-content-box{
  padding: 0px !important;
}
@media only screen and (max-width: 768px) {
  .transaction_form {
    width: 100%;
    flex-direction: column;
  }

  .search_input {
    width: 100%;
  }

  .transaction_Card,
  .review {
    width: 100%;
  }

  .sticky {
    position: absolute;
  }

  .my_dropdown_main_dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    gap: 10px;
  }

  .main_dashboard_tooltip {
    margin-top: 8px;
    width: fit-content;
    gap: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .main_heading {
    font-size: 20px;
  }

  .search_input {
    padding: 9px 10px 7px 32px;
  }

  .btn_icon {
    font-size: 16px;
    margin-top: 5px;
  }

  .sales_text {
    font-size: 16px;
  }

  .transction_main_Card h1,
  .review h1 {
    font-size: 18px;
  }
}
